import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import * as styles from './InfiniteScroll.module.scss';

type Props = {
  selectedCategories: string[];
  searchText?: string;
  categoryList: any[];
  filteredList: any[];
  completeList: any[];
  list: any[];
  setList: (list: any[]) => void;
  setFilteredList: (filteredList: any[]) => void;
};

const InfiniteScroll = ({
  selectedCategories = [],
  searchText = '',
  categoryList = [],
  filteredList = [],
  completeList = [],
  list = [],
  setList,
  setFilteredList
}: Props) => {
  const [hasMore, setHasMore] = useState(false);

  //Check if there is more
  useEffect(() => {
    const isMore = (selectedCategories.length || searchText.length) ? filteredList.length < categoryList.length : list.length < completeList.length;
    setHasMore(isMore)
  }, [filteredList, list])

  const onLoadMore = () => {
    if ((selectedCategories.length || searchText.length) && hasMore) {
      const currentLength = filteredList.length;
      const isMore = currentLength < categoryList.length;
      const nextResults = isMore
        ? categoryList.slice(currentLength, currentLength + 10)
        : [...categoryList];
      const previousList = isMore ? filteredList : [];
      setFilteredList([...previousList, ...nextResults])
    } else {
      const currentLength = list.length;
      const isMore = currentLength < completeList.length;
      const nextResults = isMore
        ? completeList.slice(currentLength, currentLength + 10)
        : [...completeList];
      const previousList = isMore ? list : [];
      setList([...previousList, ...nextResults]);
    }
  }

  return (
    <>
      <div className={classnames(styles.loadMore)}>
        {hasMore ? <button onClick={onLoadMore} className={classnames(styles.link, 'is-secondary button has-text-weight-bold')}>
          <FormattedMessage id="loadMore" defaultMessage="Load More" />
        </button> : <> </>}
      </div>
    </>
  );
}

export default InfiniteScroll
