import React from 'react';
import classnames from 'classnames';

import * as styles from './index.module.scss';

type DividerProps = {
  paddingTop?: string;
  marginBottom?: string;
};

const Divider: React.FC<DividerProps> = ({ paddingTop, marginBottom, children }) => (
  <div style={{ paddingTop, marginBottom }}>
    <div className={classnames('title is-5 black-4', styles.dividerWithText)}>{children}</div>
  </div>
);

export default Divider;
