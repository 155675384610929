import React from 'react';
import path from 'path-browserify';
import classnames from 'classnames';

import StrapiLink from '../StrapiLink/StrapiLink';

import * as styles from './Card.module.scss';

export type CardType = {
  title: string;
  slug: string;
  short?: string;
  urlText: string;
  slugBase: string;
};

const Card = ({ title, slug, short, urlText, slugBase }: CardType) => (
  <div className={classnames('bg-black-5', styles.card)}>
    <h3 className="color-a title">{title}</h3>
    <p className="black-2 is-text-2 mb-3">{short}</p>
    <StrapiLink url={path.join(slugBase, slug)} title={urlText} />
  </div>
);

export default Card;
